import React from "react";
import imgs from "../images/Rectangle 17.png";
import "./card.css";

const Card = (props) => {
  console.log(props.data.img, "datadata");
  return (
    <div className="cards">
      <img src={props.data.img} className="card-imgs" />
      <h6 className="card-name mb-0">{props.data.name1}</h6>
      <p className="card-name1 mb-0">{props.data.title}</p>
      <p className="card-location mb-0">
        {" "}
        <img src={require("../images/location 2.png")} />
        {props.data.location}
      </p>
      <p className="card-price mb-0">₹{props.data.price}</p>
    </div>
  );
};

export default Card;

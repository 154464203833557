import React from "react";
import ReactGA from "react-ga";
import logo from "../images/Ragman-removebg-preview 2.png";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();

  const Logout = async () => {
    let token = await localStorage.removeItem("userToken");
    // if (token) {
      navigate("/login");
      alert("Logout done");
    // }

    console.log("my token", token);
  };

  const event = () => {
    ReactGA.event({
      category: "Download click",
      action: "Download",
    });
  };
  return (
    <div>
      <div className="row">
        <div className="header">
          <img src={logo} />

          <a href="https://play.google.com/store/search?q=ragman&c=apps">
          <button
            type="button"
            onClick={() => event()}
            className="btn btn-dark"
          >
            Download 
          </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

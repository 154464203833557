import React, { useEffect, useState } from "react";
import "../style/blog.css";
import Category from "../components/Category";
import SearchFilter from "../components/SearchFilter";
import Footer from "./footer";
import ArticleRead from "../components/ArticleRead";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import axios from "axios";
import LatestBlog from "../components/LatestBlog";
const ArticlePageRoute = () => {
  const [poster, setPoster] = useState(null);
  useEffect(() => {
    const postUrl = "https://api.ragman.shop/api/category/getbanner";
    axios
      .get(postUrl)
      .then((response) => {
        setPoster(response.data);
      })
      .catch((error) => {
        console.error("Error Blog Poster:", error);
      });
  });
  return (
    <div className="container">
      <Navbar />
      <HeroSection />
      <Category />

      {/* Article Section */}

      <div className="mainDiv">
        <div className="art">
          <div className="innerDiv">
            <ArticleRead />
          </div>
        </div>

        {/* SearchFilter sECTION */}

        <div className="blog-poster">
          {/* <SearchFilter data={data} />  */}
          {poster?.slice(poster.length / 2).map((item) => (
            <div key={item?.image}> 
              <img src={item?.image}  alt="Loading..." />
            </div>
          ))}
        </div>
      </div>

      <div>
        <LatestBlog />
      </div>

      <Footer />
    </div>
  );
};

export default ArticlePageRoute;

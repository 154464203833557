import React from "react";
import "../styles/StoreSection.css";
import ProdPhoto from "../adminImages/Rectangle 169.png";

const StoreSection = () => {
  return (
    <>
      <div className="store-section">
        <h4>Store Details</h4>
        <div className="store-section-img">
          <div>
            <img src={ProdPhoto} alt="" />
          </div>
          <div>
            <img src={ProdPhoto} alt="" />
          </div>
        </div>
        <div className="store-section-details">
          <h6>Store Name : AK Enterprices</h6>
          <h6>Store Location : Bagpat, Utter Pradesh</h6>
          <h6>Store Discription : Online Platform</h6>
          <h6>Store Category : Clothing</h6>
        </div>

        <h6 style={{ textAlign: "center" }}>
          -------------- More Details -------------
        </h6>
        <div className="store-section-three-details">
          <div>
            <span>PRICE</span>
            <span>Rs.500</span>
          </div>
          <div>
            <span>PRICE</span>
            <span>Rs.500</span>
          </div>
          <div>
            <span>PRICE</span>
            <span>Rs.500</span>
          </div>
        </div>
        <div className="store-section-product">
          <div>
            <h5
              style={{
                textAlign: "center",
                textTransform: "uppercase",
                margin: "30px 0",
              }}
            >
              {" "}
              <span style={{ color: "green" }}> Verified </span>store products
            </h5>
          </div>
          <div className="store-products">
            <div className="store-product">
              <div className="store-product-img">
                <img src={ProdPhoto} alt="" />
              </div>
              <div className="store-product-details">
                <h6>Royal Green men suit</h6>
                <h6>Rs. 600</h6>
              </div>
            </div>
            <div className="store-product">
              <div className="store-product-img">
                <img src={ProdPhoto} alt="" />
              </div>
              <div className="store-product-details">
                <h6>Royal Green men suit</h6>
                <h6>Rs. 600</h6>
              </div>
            </div>
            <div className="store-product">
              <div className="store-product-img">
                <img src={ProdPhoto} alt="" />
              </div>
              <div className="store-product-details">
                <h6>Royal Green men suit</h6>
                <h6>Rs. 600</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreSection;

import React, { useEffect, useState } from 'react'
import AdminSearch from "../components/AdminSearch";
import NavSideBar from "../components/NavSideBar";
import AdminSearchResults from '../components/AdminSearchResults';
import Spinners from '../components/Spinners';
const SearchResultPage = () => {
  const [spinner, setSpinner] = useState(true)
  useEffect(()=>{
    setTimeout(() => {
      setSpinner(false)
    }, 4000);
  })
  return (
   <>
    <div className="admin-section">
    <NavSideBar />

    <div className="search-datail-content">
      {/* SEARCH BAR SECTION  */}
      <AdminSearch />

      {/* DOWN CONTENT SECTION */}
       {
        spinner ? (
          <Spinners/>
        ) : (
      <div className="admin-content">
        {/* DASHBOARD  */}
        <div className="admin-content-dashboard">
          <div className="dashboard-details">
            <AdminSearchResults />
          </div> 
        </div>
      </div>

        )
       }
    </div>
  </div>
   </>
  )
}

export default SearchResultPage

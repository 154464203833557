import React, { useEffect } from 'react'
import AdminSearch from "../components/AdminSearch";

import UserPost from "../components/UserPost";
import NavSideBar from "../components/NavSideBar";
import StoreSection from "../components/StoreSection";
import { useNavigate } from 'react-router-dom';

const StoreAll = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getToken();
  }, []);
  const getToken = async () => {
    let token = await localStorage.getItem("userToken");
    if (!token) {
      navigate("/admin/login");
    }
  };
  return (
    <div className="admin-section">
    {/* NAVSIDE BAR  */}
    <NavSideBar />

    <div className="search-datail-content">
      {/* SEARCH BAR SECTION  */}
   <AdminSearch/>

      {/* DOWN CONTENT SECTION */}

      <div className="admin-content">
        {/* DASHBOARD  */}
        <div className="admin-content-dashboard">
          <div className="dashboard-details">
            <StoreSection/>
          </div>
        </div>
        {/* POSTS  */}
        <UserPost />
      </div>
    </div>
  </div>
  )
}

export default StoreAll

import React, { useEffect, useState } from "react";
import BlogForm from "../components/BlogForm";
import AdminSearch from "../components/AdminSearch";
import NavSideBar from "../components/NavSideBar";
import Spinners from '../components/Spinners';

import { useNavigate } from "react-router-dom";
const AdminBlogFormPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getToken();
  }, []);
  const getToken = async () => {
    let token = await localStorage.getItem("userToken");
    if (!token) {
      navigate("/admin/login");
    }
  };
  const [spinner, setSpinner] = useState(true)
  useEffect(()=>{
    setTimeout(() => {
      setSpinner(false)
    }, 4000);
  })

  return (
    <div className="admin-section">
      <NavSideBar />

      <div className="search-datail-content">
        {/* SEARCH BAR SECTION  */}
        <AdminSearch />

        {/* DOWN CONTENT SECTION */}
         {
          spinner ? (
            <Spinners/>
          ) : (
        <div className="admin-content">
          {/* DASHBOARD  */}
          <div className="admin-content-dashboard">
            <div className="dashboard-details">
              <BlogForm />
            </div>
          </div>
        </div>

          )
         }
      </div>
    </div>
  );
};

export default AdminBlogFormPage;

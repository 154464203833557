import React, { useContext, useEffect, useState } from "react";
import "../styles/UserDocument.css";
import { VscVerified } from "react-icons/vsc";
import { MdOutlineCancel } from "react-icons/md";
import { UserContext } from "../../context/userContext";
import axios from "axios";
import Spinners from "./Spinners";

import { useNavigate } from "react-router-dom";

const UserDocument = () => {
  const navigate = useNavigate();
  const { userInfo, setUserId } = useContext(UserContext);
  const [acceptLoader, setAcceptLoader] = useState(false);
  const [rejectLoader, setRejectLoader] = useState(false);

  const [userDocuments, setUserDocuments] = useState(null);

  const docsUrl = "https://api.ragman.shop/admin/getuser/getAllDocuments";
  useEffect(() => {
    const authToken = userInfo?.token;

    axios
      .get(docsUrl, {
        headers: {
          Authorization: `Bearer ${authToken}`, // Include the token in the headers
          "Content-Type": "application/json", // Set other headers as needed
        },
      })
      .then((response) => {
        setUserDocuments(response.data.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [userInfo, acceptLoader, rejectLoader]);

  const handleClickUserProfile = (usrId) => {
    setUserId(usrId);
    navigate(`/admin/dashboard/${usrId}`);
  };

  const acceptUrl = "https://api.ragman.shop/admin/getuser/updateDocuments";
  const handleAcceptDocs = (docsId) => {
    const authToken = userInfo?.token;

    const requestBody = {
      docId: docsId,
      status: "verified",
    };

    const isConfirmed = window.confirm(
      "Are you sure you want to verify this documents?"
    );

    if (!isConfirmed) {
      return;
    }
    axios
      .put(acceptUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setAcceptLoader(true);
      })
      .catch((error) => {
        console.error("Error in put request:", error);
      });
  };
  const handleRejectDocs = (docsId) => {
    const authToken = userInfo?.token;

    const requestBody = {
      docId: docsId,
      status: "rejected",
    };

    const isConfirmed = window.confirm(
      "Are you sure you want to reject this docuements?"
    );

    if (!isConfirmed) {
      return;
    }
    axios
      .put(acceptUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setRejectLoader(true);
      })
      .catch((error) => {
        console.error("Error in put request:", error);
      });
  };

  return (
    <>
      {userDocuments ? (
        <div className="user-document">
          <h4>personal information and document</h4>
          {userDocuments?.map((item) => (
            <div key={item?._id} className="user-document-map">
              <div className="user-document-user">
                <div
                  className="user-document-user-img"
                  onClick={() => handleClickUserProfile(item.profile.user._id)}
                >
                  <img src={item?.profile.avatar} alt="Loading..." />
                </div>
                <div className="user-document-user-detail">
                  <h6>
                    Name : <span>{item?.profile.name}</span>
                  </h6>
                  <h6>
                    Mobail Number: <span>+91-{item?.profile.user.phone}</span>
                  </h6>
                  <h6>
                    Email : <span>{item?.profile.user.email}</span>
                  </h6>
                </div>
                <div className="user-document-user-bages">
                  <button
                    style={{ backgroundColor: "rgb(121, 196, 121)" }}
                    onClick={() => handleAcceptDocs(item?._id)}
                  >
                    {" "}
                    <i>
                      <VscVerified />
                    </i>
                    Verify
                  </button>
                  <button
                    style={{ backgroundColor: "pink" }}
                    onClick={() => handleRejectDocs(item?._id)}
                  >
                    {" "}
                    <i>
                      <MdOutlineCancel />
                    </i>{" "}
                    Reject
                  </button>
                </div>
              </div>

              <div className="user-document-images">
                <div className="user-docs-image-slider">
                  {item?.docs.map((image, index) => (
                    <div key={index} className="user-docs-carousel-image">
                      <img src={image} alt="loading..." />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Spinners />
      )}
    </>
  );
};

export default UserDocument;

import React, { useContext, useEffect, useState } from "react";
import { LiaEdit } from "react-icons/lia";
import { RiDeleteBin6Line } from "react-icons/ri";
import "../styles/UserPost.css";
import { UserContext } from "../../context/userContext";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const UserPost = () => {
  const navigate = useNavigate();
  const { usrId } = useParams();
  const [random, setRandom] = useState("state not change");
  const { searchUserData, userInfo, userPosts, setUserPosts, setPostDetail } =
    useContext(UserContext);
  const showToastMessage = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const apiUrl = "https://api.ragman.shop/admin/getuser/adminGetAllbyid";
  useEffect(() => {
    const authToken = userInfo?.token;
    const requestBody = {
      profileId: searchUserData?._id,
    };

    axios
      .post(apiUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setUserPosts(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [userInfo, searchUserData, random]);
  const handlePostClick = (post) => {
    setPostDetail(post);
    navigate(`/admin/${usrId}/productdetail`);
  };

  const deleteUrl = "https://api.ragman.shop/admin/getuser/adminDeletePostById";
  const handleDelete = (postid) => {
    const authToken = userInfo?.token;

    const requestBody = {
      postId: postid,
    };
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this post?"
    );

    if (!isConfirmed) {
      return;
    }

    axios
      .delete(deleteUrl, {
        data: requestBody,
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setRandom("state change");
        showToastMessage(response.message);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
      {userPosts?.length > 0 ? (
        <div className="admin-content-posts">
          <div className="admin-content-postunder">
            <h6>Post posted by {searchUserData?.name}</h6>

            {userPosts?.map((item) => (
              <div
                className="admin-content-post"
                key={item._id}
                onClick={() => handlePostClick(item)}
              >
                <div className="admin-content-post-img">
                  <img src={item?.image[0]} alt="Loading..." />
                </div>
                <div className="admin-content-post-detail">
                  <p>{item?.productName}</p>
                  <p style={{ marginTop: "-13px" }}>
                    Posted Date: 16 Augest 2023
                  </p>
                  <div className="admin-content-detail-btn">
                    <button onClick={() => handleDelete(item._id)}>
                      <i>
                        <RiDeleteBin6Line />
                      </i>
                      Delete
                    </button>
                    <button>
                      <i>
                        <LiaEdit />
                      </i>
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <ToastContainer />
        </div>
      ) : (
        <div className="post-not-found">Posts Not Found!</div>
      )}
    </>
  );
};

export default UserPost;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../style/category.css";
import axios from "axios";

const CategorySection = () => {
  let navigate = useNavigate();

  // STATES OF THE COMPONENTS
  const [dropdown, setdropdown] = useState(false);
  const [activeSection, setActiveSection] = useState(""); // State to track the active section
  const [categoryList, setCategoryList] = useState([]);

  // FUNCTIONS
  const handleSectionClick = (sectionId) => {
    if (sectionId == "All") {
      navigate("/");
    }
    setActiveSection(sectionId); // Update the active section
  };

  const CategoryUrl = 'https://api.ragman.shop/api/category/getAllCategory';
  useEffect(() => {
    axios
      .get(CategoryUrl)
      .then((response) => {
        setCategoryList(response.data.category);
      })
      .catch((error) => {
        console.error("Error fetching quiz data:", error);
      });
  }, []);

  return (
    <div className="category-section">
      <ul className="category-list">
        {categoryList?.slice(0, 5).map((item, index) => (
          <li key={index} className="category-item">
            <a
              href={`/blog/${item.name}/page/1`}
              onClick={() => handleSectionClick("All")}
              className={activeSection === "All" ? "active" : ""}
            >
              {item?.name}
            </a>
          </li>
        ))}

        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            All Categories
          </a>
          <div className="dropdown-menu">
            <ul>
              {categoryList?.slice(0, 9).map((item, index) => (
                <li key={index}>
                  <a
                    className="dropdown-item"
                    href={`/blog/${item.name}/page/1`}
                  >
                    {item?.name}
                  </a>
                </li>
              ))}
            </ul>
            <ul>
              {categoryList?.slice(6, 15).map((item, index) => (
                <li key={index}>
                  <a
                    className="dropdown-item"
                    href={`/blog/${item.name}/page/1`}
                  >
                    {item?.name}
                  </a>
                </li>
              ))}
            </ul>
            <ul>
              {categoryList?.slice(16, 25).map((item, index) => (
                <li key={index}>
                  <a
                    className="dropdown-item"
                    href={`/blog/${item.name}/page/1`}
                  >
                    {item?.name}
                  </a>
                </li>
              ))}
            </ul>
            <ul>
              {categoryList?.slice(26, 35).map((item, index) => (
                <li key={index}>
                  <a className="dropdown-item" href={`/blog/${item.name}`}>
                    {item?.name}
                  </a>
                </li>
              ))}
            </ul>
            <ul>
              {categoryList
                ?.slice(36, categoryList.length)
                .map((item, index) => (
                  <li key={index}>
                    <a
                      className="dropdown-item"
                      href={`/blog/${item.name}/page/1`}
                    >
                      {item?.name}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default CategorySection;

import React, { useContext, useState } from "react";
import "../styles/AdminSearch.css";
import { GoSearch } from "react-icons/go";
import { BsBell } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { UserContext } from "../../context/userContext";

const AdminSearch = () => {
  const showToastMessage = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const navigate = useNavigate();
  const { usrId } = useParams();

  const { userInfo, setAdminSearchData, userId, setUserId , searchUserData } =
    useContext(UserContext);
    if (usrId) setUserId(usrId);
  // console.log(searchUserData);
  const [SearchAdmin, setSearchAdmin] = useState(null);


  const Logout = async () => {
    await localStorage.removeItem("userToken");
    navigate("/admin/login");
    showToastMessage("Logout Done");
  };

  const handleOnChange = (e) => {
    setSearchAdmin(e.target.value);
  };
  const apiUrl = "https://api.ragman.shop/admin/getuser/adminserachuser";

  const handleSearchClick = (e) => {
    e.preventDefault();
    if (!SearchAdmin) 
    {
      showToastMessage("Please Enter UserName");
      return;
    }
    else {
      const authToken = userInfo?.token;
      const requestBody = {
        name: SearchAdmin,
      };

      axios
        .post(apiUrl, requestBody, {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the token in the headers
            "Content-Type": "application/json", // Set other headers as needed
          },
        })
        .then((response) => {
          setAdminSearchData(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      }
      navigate("/admin/search-results");
  };
  return (
    <div className="search-top-bar">
      <div className="top-search-bar">
        <form onSubmit={handleSearchClick}>
          <input
            type="text"
            onChange={handleOnChange}
            name="name"
            placeholder="Search for name, email, mobial number"
          />

          <i onClick={handleSearchClick}>
            <GoSearch />
          </i>
          {/* </button> */}
        </form>
      </div>

      <div className="top-search-icon">
        <div>
          <i>
            <BsBell />
          </i>
        </div>
        <div>
          <Link to={`/admin/dashboard/${userId}`}>
            <img src={searchUserData?.avatar} alt="" />
          </Link>
        </div>
        <div>
          <button onClick={() => Logout()}>Logout</button>
        </div>

        <ToastContainer />
      </div>
    </div>
  );
};

export default AdminSearch;

import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Logo from "../adminImages/ragmanlogo.png";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Spinner } from "react-bootstrap";
import { UserContext } from "../../context/userContext";

const showToastMessage = (msg) => {
  toast.error(msg, {
    position: toast.POSITION.TOP_CENTER,
  });
};

const defaultTheme = createTheme();

function AdminLogin() {
  //  USE PARAMS
  const navigate = useNavigate();
  const { userId } = useContext(UserContext);

  // STATE OF THE COMPONENT
  const [phoneno, setPhoneno] = useState("");
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [checkapp, setCheckApp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // FUNCTIONS
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const checkfunction = () => setCheckApp(!checkapp);

  useEffect(() => {
    getData();
    getToken();
  }, []);
  const getToken = async () => {
    let token = await localStorage.getItem("userToken");
    if (token) {
      navigate("/admin/blogform");
    }
  };

  const getData = async () => {
    let phoneNo = await localStorage.getItem("phone");
    let Password = await localStorage.getItem("password");
    setPhoneno(phoneNo);
    setPassword(Password);
  };
  const LoginUrl = 'https://ragman.shop/api/users/login';
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log("submit button click");
    var phone = data.get("phone");
    var password = data.get("password");
    if (!phone || !password) {
      showToastMessage("fill all fields");
    }
    if (checkapp === true) {
      localStorage.setItem("phone", phone);
      localStorage.setItem("password", password);
    } else if (phoneno && password) {
      await fetch(LoginUrl, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone: phone, password: password }),
      })
        .then((response) => response.json())
        .then((response) => {
          localStorage.setItem("userLoginInfo", JSON.stringify(response));

          if (response.error) {
            // alert("invalid cred");
            showToastMessage("Invalid Credentials");

            setIsLoading(false);
          }
          if (response.token) {
            console.log("success Login");

            localStorage.setItem("userToken", response.token);
            let isAdmin = response.user.isAdmin;
            console.log(isAdmin);
            if (isAdmin) {
              navigate(`/admin/dashboard/${userId}`);
            } else {
              showToastMessage("This User is not Approved");
            }
            return response;
          }
        });
    } else {
      localStorage.removeItem("phone");
      localStorage.removeItem("password");
    }
  };

  const handleClick = () => {
    if (phoneno && password) setIsLoading(true);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: 7,
            p: 8,
          }}
        >
          <img src={Logo} alt="Loading..." />
          <br />

          <Typography component="h6" variant="h5">
            Welcome to Ragman
          </Typography>
          <br />
          <p>Please sign-in to your account</p>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="phone"
              value={phoneno}
              onChange={(event) => setPhoneno(event.target.value)}
              label="Phone number"
              name="phone"
              autoComplete="phone"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              value={password}
              name="password"
              label="Password"
              id="password"
              autoComplete="current-password"
              type={showPassword ? "text" : "password"} // <-- This is where the magic happens
              onChange={(event) => setPassword(event.target.value)}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  checked={checkapp}
                  onClick={checkfunction}
                  color="primary"
                />
              }
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleClick}
              //  disabled={isLoading}
              sx={{ mt: 3, mb: 2 }}
            >
              {isLoading ? <Spinner /> : "Sign In"}
            </Button>

            <Grid container></Grid>
          </Box>
        </Box>
        <ToastContainer />
      </Container>
    </ThemeProvider>
  );
}
export default AdminLogin;

import React from "react";
import "../styles/Spinners.css";
import Loader from "../adminImages/spinner.gif";

const Spinners = () => {
  return (
    <div>
      <div className="spinners">
        <img src={Loader} alt="error" className="spinner-img" />
        <span>Loading...</span>
      </div>
    </div>
  );
};

export default Spinners;

import React from "react";
import "../style/HeroSection.css";
import { HiArrowRight } from "react-icons/hi";

const HeroSection = () => {
  return (
    <div>
      <div className="hero">
        <h1>Welcome to Our Blog</h1>
        <p className="subtext">
          Stay updated with the latest design and development stories, case
          studies, and insights shared by the Ragman team.
        </p>
        <p style={{ fontSize: "20px" }}>
          Learn more <HiArrowRight />
        </p>
      </div>
    </div>
  );
};

export default HeroSection;

import React, { useContext, useEffect, useState } from "react";
import "../styles/NavSideBar.css";
import Logo from "../adminImages/ragmanlogo.png";
import { AiOutlineHome } from "react-icons/ai";
import { IoIosLogIn } from "react-icons/io";
import { HiOutlineDocumentText } from "react-icons/hi";
import { Link, useLocation, useParams } from "react-router-dom";
import { UserContext } from "../../context/userContext";
const NavSideBar = () => {
  const { usrId } = useParams();
  const { userId, setUserId, userInfo } = useContext(UserContext);
  if (usrId) setUserId(usrId);

  const location = useLocation();
  const [active, setActive] = useState("dashboard");
  useEffect(() => {
    if (location.pathname === "/admin/blogform") setActive("blogform");
    if (location.pathname === "/admin/userdocuments") setActive("document");
    if (location.pathname === `/admin/${usrId}`) setActive("dashboard");
  }, [active]);
  return (
    <div className="nav-sidebar">
      <div className="nav-sidebar-logo">
        <img src={Logo} alt="Loading..." />
      </div>

      <ul className="nav-sidebar-list">
        <li className={active === "dashboard" ? "active" : ""}>
          <Link to={`/admin/dashboard/${userId}`}>
            <i>
              <AiOutlineHome />
            </i>
            Dashbord
          </Link>
        </li>
        <li className={active === "blogform" ? "active" : ""}>
          <Link to="/admin/login">
            <i>
              <IoIosLogIn />
            </i>

            {userInfo?.token ? "Blog Form" : "Login"}
          </Link>
        </li>
        <li className={active === "document" ? "active" : ""}>
          <Link to="/admin/userdocuments">
            <i>
              <HiOutlineDocumentText />
            </i>
            Document
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default NavSideBar;

import React, { useContext, useEffect, useState } from "react";
import "../styles/CardDetails.css";
import axios from "axios";
import { UserContext } from "../../context/userContext";
import { useParams } from "react-router-dom";
// icons
import { MdBlock, MdDomainVerification, MdOutlineCancel } from "react-icons/md";
import { CgUnblock } from "react-icons/cg";
import { VscVerified } from "react-icons/vsc";

const CardDetails = () => {
  // params
  const { usrId } = useParams();
  // context
  const { searchUserData, userPosts, userInfo } = useContext(UserContext);
  // states
  const [userDocsApi, setUserDocsApi] = useState(null);
  const PostLength = userPosts?.length;

  // fucntions

  const handleUnBlockUser = (userPhone) => {
    const unBlockUserUrl = "https://api.ragman.shop/api/block/unblockuser";
    const authToken = userInfo?.token;
    const requestBody = {
      phone: userPhone,
    };
    const isConfirmed = window.confirm(
      "Are you sure you want to UnBlock this user?"
    );

    if (!isConfirmed) {
      return;
    }

    axios
      .delete(unBlockUserUrl, {
        data: requestBody,
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleBlockUser = (userId) => {
    const blockUserUrl = "https://api.ragman.shop/api/block/blockuser";
    const authToken = userInfo?.token;
    const requestBody = {
      phone: searchUserData?.user.phone,
      profile: userId,
    };
    const isConfirmed = window.confirm(
      "Are you sure you want to Block this user?"
    );

    if (!isConfirmed) {
      return;
    }

    axios
      .post(blockUserUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleVerifyUser = (userId) => {
    const verifyUrl =
      "https://api.ragman.shop/admin/getuser/updateProfileByAdmin";
    const authToken = userInfo?.token;

    const requestBody = {
      profileId: userId,
      status: "true",
    };

    const isConfirmed = window.confirm(
      "Are you sure you want to verify this user?"
    );

    if (!isConfirmed) {
      return;
    }
    axios
      .put(verifyUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.error("Error in put request:", error);
      });
  };

  const handleVerifyDocs = (docsId) => {
    const acceptUrl = "https://api.ragman.shop/admin/getuser/updateDocuments";

    const authToken = userInfo?.token;

    const requestBody = {
      docId: docsId,
      status: "verified",
    };

    const isConfirmed = window.confirm(
      "Are you sure you want to verify this documents?"
    );

    if (!isConfirmed) {
      return;
    }
    axios
      .put(acceptUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
      })
      .catch((error) => {
        console.error("Error in put request:", error);
      });
  };
  const handleRejectDocs = (docsId) => {
    const acceptUrl = "https://api.ragman.shop/admin/getuser/updateDocuments";

    const authToken = userInfo?.token;

    const requestBody = {
      docId: docsId,
      status: "rejected",
    };

    const isConfirmed = window.confirm(
      "Are you sure you want to Reject this documents?"
    );

    if (!isConfirmed) {
      return;
    }
    axios
      .put(acceptUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
      })
      .catch((error) => {
        console.error("Error in put request:", error);
      });
  };

  // fetch docs by id
  useEffect(() => {
    const fetchUserDocsUrl =
      "https://api.ragman.shop/admin/getuser/getDocumnetsById";
    const authToken = userInfo?.token;
    const requestBody = {
      profileId: searchUserData?._id,
    };
    axios
      .post(fetchUserDocsUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setUserDocsApi(response.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [[usrId, userInfo]]);

  return (
    <div className="user-details-btns">
      <div className="user-card-detail">
        {searchUserData?.verification ? (
          <div className="user-card-badge user-verify">Verified User</div>
        ) : (
          <div className="user-card-badge user-unverify">Unverified User</div>
        )}

        {searchUserData?.block ? (
          <div className="user-card-badgess user-verify">Blocked</div>
        ) : (
          <div className="user-card-badgess user-unverify">UnBlocked</div>
        )}

        <div className="user-card-details">
          <h4>
            <span style={{ color: "green" }}>Welcome back ! </span>{" "}
            {searchUserData?.name}
          </h4>
          <div className="user-card-div">
            <span>User Post {PostLength}</span>
          </div>
          {/* <div className="user-card-div">
          <span>User Store {PostLength}</span>
        </div> */}
        </div>

        <div className="user-card-img">
          <img src={searchUserData?.avatar} alt="" />
        </div>
      </div>

      <div className="active-btns">
        <h4>Active Button</h4>
        <div className="active-btn">
          <div className="active-btn1">
            <button
              disabled={searchUserData?.block ? "disabled" : ""}
              className={
                searchUserData?.block ? "active-btn-disable" : "active-btn-btn"
              }
              onClick={() => handleBlockUser(searchUserData?._id)}
            >
              <i>
                <MdBlock />
              </i>
              Block
            </button>
            <button
              disabled={searchUserData?.block ? "" : "disabled"}
              className={
                searchUserData?.block ? "active-btn-btn" : "active-btn-disable"
              }
              onClick={() => handleUnBlockUser(searchUserData?.user.phone)}
            >
              <i>
                <CgUnblock />
              </i>
              Unblock
            </button>
            <button
              onClick={() => handleVerifyDocs(userDocsApi?._id)}
              disabled={userDocsApi?.status === "verified" ? "disabled" : ""}
              className={
                userDocsApi?.status === "verified"
                  ? "active-btn-disable"
                  : "active-btn-btn"
              }
            >
              <i>
                <VscVerified />
              </i>
              Verify
            </button>
          </div>

          <div className="active-btn2">
            <button
              onClick={() => handleVerifyUser(searchUserData?._id)}
              disabled={searchUserData?.verification ? "disabled" : ""}
              className={
                searchUserData?.verification
                  ? "active-btn-disable"
                  : "active-btn-btn"
              }
            >
              <i>
                <MdDomainVerification />
              </i>
              Verification
            </button>
            <button
              onClick={() => handleRejectDocs(userDocsApi?._id)}
              disabled={userDocsApi?.status === "rejected" ? "disabled" : ""}
              className={
                userDocsApi?.status === "rejected"
                  ? "active-btn-disable"
                  : "active-btn-btn"
              }
            >
              <i>
                <MdOutlineCancel />
              </i>
              Rejected
            </button>
          </div>
        </div>
      </div>

      <div className="user-document-images-for-dashboard">
        {userDocsApi?.status === "verified" ? (
          <div className="user-verify docs-verify-badge">Verified</div>
        ) : userDocsApi?.status === "rejected" ? (
          <div className="user-reject docs-verify-badge">Rejected</div>
        ) : (
          <div className="user-unverify docs-verify-badge">Unverified</div>
        )}
        <div className="user-docs-image-slider">
          {userDocsApi?.docs.map((image, index) => (
            <div key={index} className="user-docs-carousel-image-dashboard">
              <img src={image} alt="loading..." />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardDetails;

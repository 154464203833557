import React from "react";
import Footer from "./footer";
export default function Refund() {
  return (
    <div className="container">
      <h1 style={{ color: "black" }}> Refund and Cancellation Policy :</h1>
      <p>
        This subscription model is a digital offering, and Ragman will not
        provide a refund post completion of the payment. The user may not
        request a refund from Ragman unless the subscription service cannot be
        used generally due to a platform failure after due diligence. If the
        applicant's account is closed due to inappropriate behavior on Ragman's
        platform, no refund will be issued in such cases. If the user has any
        concerns/objections, they can contact Ragman's customer support team.
        Payment Terms and Conditions : 'Ragman' may offer products and services
        for purchase on the App (“In-App Purchase”). By making an in-app
        purchase, the subscriber agrees to the payment terms that may apply,
        which are disclosed at the point of purchase, and as such, the payment
        terms are incorporated herein by reference. In-App Purchases can be made
        by the following methods ("Payment Method"): (a) making a purchase
        through a third-party platform such as the Apple App Store or Google
        Play Store ("Third Party Store"). As soon as someone makes an In-App
        Purchase, they authorize Ragman to charge their chosen Payment Method.
        If Ragman cannot receive payment through the selected Payment Method,
        the customer agrees to pay all sums due upon demand by Ragman. The
        subscription is not canceled when an account is deleted, or an
        application is deleted from a device. The subscriber will be notified of
        any changes to the pricing of the Premium Services to which they have
        subscribed and given the opportunity to cancel. If Ragman changes these
        prices and the customer does not cancel their subscription, they will be
        charged at Ragman's then-current subscription pricing. Canceling
        Subscriptions : If someone has purchased a subscription through a Third
        Party Store, such as the Apple App Store or the Google Play Store, they
        will need to access their account with that Third Party Store and follow
        instructions to change or cancel their subscription. If a subscriber
        cancels their subscription, they may continue to use the paid services
        until the end of the period they last paid for, but (i) the subscriber
        will not (except as outlined in the subsection entitled “Refunds” below)
        be eligible for a prorated refund. (ii) they will then no longer be able
        to use the Premium Services or In-App Purchases enabled by their
        subscription. Note : Generally, all charges for purchases are
        non-refundable, and there are no refunds or credits for partially used
        periods. The subscriber may cancel their subscription without penalty or
        obligation. Their estate shall be entitled to a refund of the amount for
        any payment subscribe had made allocated to the period post-death if
        they die before the end of their subscription period. Suppose one
        becomes disabled (so that they cannot use Ragman) before the end of
        their subscription period. In that case, they are entitled to a refund
        of that portion of their subscription payment transferable to the period
        after their disability by providing the company notice; in the same
        manner, they request a refund. Suppose a subscriber exercises their
        right of cancellation (except for purchases made through Apple ID, which
        Apple controls). In that case, Ragman will refund (or ask Google to
        refund) all payments received from the subscriber without undue delay
        and, in any case, within 14 days of the date when Ragman received
        notification of the subscriber's decision to cancel the Agreement.
        Ragman shall make such a refund using the same means of payment as used
        by the subscriber in the initial transaction. In any case, no fees will
        be charged to the subscriber due to the refund. The subscriber who
        purchased through a payment platform other than those listed above
        should request a refund directly from the third-party merchant through
        which they made the purchase. A subscriber cannot cancel an order for
        digital content not delivered on a physical medium if they have given
        their explicit prior consent and have acknowledged that they will lose
        their cancellation rights. To request a Refund : If the subscriber
        wishes to receive a refund, they must cancel the subscription and
        request a refund. If they subscribed using their Apple ID, refunds are
        handled by Apple, not Ragman. Go to iTunes, click on your Apple ID,
        select “Purchase history,” find the transaction, and hit “Report
        Problem” to request a refund. The subscriber can also submit a request
        at For all other purchases, Ragman will refund (or ask Google to refund)
        all payments received from the subscriber within 21 days of the date
        Ragman received notice of the subscriber's decision to cancel the
        Agreement. Ragman will refund the subscriber's payment using the same
        method as the original transaction. The subscriber will not be charged
        any fees for the refund. If the subscriber made a purchase using a
        payment platform not listed above, they should request a refund directly
        from the third-party merchant through which they made the purchase. A
        subscriber may not cancel an order for delivery of digital content that
        is not delivered on a physical medium so long as order processing has
        begun with their prior consent and acknowledgement that they may lose
        their cancellation rights. Taxes : All prices include taxes for payments
        collected via In-App payments for the product or service. If Ragman
        determines that it is legally obliged to collect a Sales Tax or GST from
        the subscriber, the Sales Tax or GST will be collected in addition to
        the required payments.
      </p>
      <Footer />
    </div>
  );
}

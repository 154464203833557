import React, { useEffect, useState } from "react";
import axios from "axios";
import Spinner from "./Spinner";
import { useParams } from "react-router-dom";
import ArtImg2 from "../images/artimg2.png";
import ArtImg3 from "../images/artimg3.png";
import "../style/article.css";
const ArticleRead = () => {
  // PARAMS
  const { title } = useParams();
  const decodedTitle = title.replace(/-/g, " ");

  // STATES OF COMPONENTS
  const [expanded, setExpanded] = useState(false);
  const [article, setArticle] = useState({});
  const [load, setLoad] = useState(false);

  // FUNCTIONS
  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const articleUrl ='htpps://api.ragman.shop/api/blogs/getCategoryById' + `?title=${decodedTitle}`;
  // FETCH DATA FROM API
  useEffect(() => {
    axios
      .get(articleUrl)
      .then((response) => {
        const responseData = JSON.parse(JSON.stringify(response.data));
        setArticle(responseData[0]);
        setLoad(true);
      })
      .catch((error) => {
        console.error("Error fetching article details:", error);
      });
  }, []);

  return (
    <>
      {!load ? (
        <Spinner />
      ) : (
        <div className="article-section">
          {article?.title === "" ? null : (
            <div style={{ marginBottom: "50px" }}>
              <div className="article-image-container">
                <img
                  src={article?.image}
                  alt="Article"
                  className="article-image"
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <h2>{article?.title}</h2>
              <p>{article?.description}</p>
            </div>
          )}

          {article?.headingone === "" ? null : (
            <div style={{ marginBottom: "50px" }}>
              <div className="article-image-container">
                <img
                  src={article?.imageone}
                  alt="Article"
                  className="article-image"
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <h2>{article?.headingone}</h2>
              <p>{article?.descriptionone}</p>
            </div>
          )}
          {article?.headingtwo === "" ? null : (
            <div style={{ marginBottom: "50px" }}>
              <div className="article-image-container">
                <img
                  src={article?.imagetwo}
                  alt="Article"
                  className="article-image"
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <h2>{article?.headingtwo}</h2>
              <p>{article?.descriptiontwo}</p>
            </div>
          )}
          {article?.headingthree === "" ? null : (
            <div style={{ marginBottom: "50px" }}>
              <div className="article-image-container">
                <img
                  src={article?.imagethree}
                  alt="Article"
                  className="article-image"
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <h2>{article?.headingthree}</h2>
              <p>{article?.descriptionthree}</p>
            </div>
          )}
          {article?.headingfour === "" ? null : (
            <div style={{ marginBottom: "50px" }}>
              <div className="article-image-container">
                <img
                  src={article?.imagefour}
                  alt="Article"
                  className="article-image"
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <h2>{article?.headingfour}</h2>
              <p>{article?.descriptionfour}</p>
            </div>
          )}
          {article?.headingfive === "" ? null : (
            <div style={{ marginBottom: "50px" }}>
              <div className="article-image-container">
                <img
                  src={article?.imagefive}
                  alt="Article"
                  className="article-image"
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <h2>{article?.headingfive}</h2>
              <p>{article?.descriptionfive}</p>
            </div>
          )}
          {article?.headingsix === "" ? null : (
            <div style={{ marginBottom: "50px" }}>
              <div className="article-image-container">
                <img
                  src={article?.imagesix}
                  alt="Article"
                  className="article-image"
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <h2>{article?.headingsix}</h2>
              <p>{article?.descriptionsix}</p>
            </div>
          )}
          {article?.headingseven === "" ? null : (
            <div style={{ marginBottom: "50px" }}>
              <div className="article-image-container">
                <img
                  src={article?.imageseven}
                  alt="Article"
                  className="article-image"
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <h2>{article?.headingseven}</h2>
              <p>{article?.descriptionseven}</p>
            </div>
          )}
          {article?.headingeight === "" ? null : (
            <div style={{ marginBottom: "50px" }}>
              <div className="article-image-container">
                <img
                  src={article?.imageeight}
                  alt="Article"
                  className="article-image"
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <h2>{article?.headingeight}</h2>
              <p>{article?.descriptioneight}</p>
            </div>
          )}
          {article?.headingnine === "" ? null : (
            <div style={{ marginBottom: "50px" }}>
              <div className="article-image-container">
                <img
                  src={article?.imagenine}
                  alt="Article"
                  className="article-image"
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <h2>{article?.headingnine}</h2>
              <p>{article?.descriptionnine}</p>
            </div>
          )}
          {article?.headingten === "" ? null : (
            <div style={{ marginBottom: "50px" }}>
              <div className="article-image-container">
                <img
                  src={article?.imageten}
                  alt="Article"
                  className="article-image"
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <h2>{article?.headingten}</h2>
              <p>{article?.descriptionten}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ArticleRead;

import React, { useEffect } from "react";
import ProductSection from "../components/ProductSection";
import AdminSearch from "../components/AdminSearch";

import UserPost from "../components/UserPost";
import NavSideBar from "../components/NavSideBar";
import { useNavigate } from "react-router-dom";
const ProductAll = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getToken();
  }, []);
  const getToken = async () => {
    let token = await localStorage.getItem("userToken");
    if (!token) {
      navigate("/admin/login");
    }
  };
  return (
    <div className="admin-section">
      <NavSideBar />

      <div className="search-datail-content">
        {/* SEARCH BAR SECTION  */}
        <AdminSearch />

        {/* DOWN CONTENT SECTION */}

        <div className="admin-content">
          {/* DASHBOARD  */}
          <div className="admin-content-dashboard">
            <div className="dashboard-details">
              <ProductSection />
            </div>
          </div>
          {/* POSTS  */}
          <UserPost />
        </div>
      </div>
    </div>
  );
};

export default ProductAll;

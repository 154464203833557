import React, { useEffect, useState } from "react";
import "../styles/BlogForm.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const BlogForm = () => {
  const navigate = useNavigate();

  const showToastMessage = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // STATE OF THE COMPONENT
  const [token, setToken] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [blogpost, setBlogpost] = useState({
    category: "",

    image: "",
    title: "",
    description: "",

    imageone: "",
    headingone: "",
    descriptionone: "",

    imagetwo: "",
    headingtwo: "",
    descriptiontwo: "",

    imagethree: "",
    headingthree: "",
    descriptionthree: "",

    imagefour: "",
    headingfour: "",
    descriptionfour: "",

    imagefive: "",
    headingfive: "",
    descriptionfive: "",

    imagesix: "",
    headingsix: "",
    descriptionsix: "",

    imageseven: "",
    headingseven: "",
    descriptionseven: "",

    imageeight: "",
    headingeight: "",
    descriptioneight: "",

    imagetnine: "",
    headingnine: "",
    descriptionnine: "",

    imagetten: "",
    headingten: "",
    descriptionten: "",
  });

  // FUNCTIONS

  const createBlogUrl = 'https://api.ragman.shop/api/blogs/createblog';
  const SubmitForm = async (e) => {
    e.preventDefault();
    const { category, title, image, description } = blogpost;
    if (!category || !title || !image || !description) {
      showToastMessage("Please fill all fields");
    } else {
      try {
        axios({
          method: "POST",
          url: createBlogUrl,
          headers: { Authorization: "Bearer " + token },
          data: blogpost,
        }).then((e) => {
          console.log(e.data);
        });
      } catch (error) {
        console.log("blogform data error", error);
      }
    }
  };
  const handleInput = (e) => {
    setBlogpost({
      ...blogpost,
      [e.target.name]: e.target.value,
    });
  };

  const api = "https://ragmanindia.onrender.com/api/category/getAllCategory";
  useEffect(() => {
    axios
      .get(api)
      .then((response) => {
        setCategoryList(response.data.category);
      })
      .catch((error) => {
        console.error("Error fetching quiz data:", error);
      });
  }, []);

  return (
    <div className="container">
      <div className="contactDetail">
        <div className="blogform">
          <form>
            <div className="input-group">
              <span>Title</span>
              <input
                type="text"
                required
                placeholder="Enter title"
                name="title"
                onChange={handleInput}
                value={blogpost.title}
              />
            </div>

            <div className="blogform-category">
              <span>Category : </span>
              <select
                name="category"
                value={blogpost.category}
                onChange={handleInput}
              >
                <option value="select">select</option>
                {categoryList.map((category) => (
                  <option key={category.name} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="input-group">
              <span>Image Url</span>
              <input
                type="text"
                required
                onChange={handleInput}
                placeholder="Enter Image Url"
                name="image"
                value={blogpost.image}
              />
            </div>

            <div className="input-group">
              <span>Description</span>
              <textarea
                rows="8"
                onChange={handleInput}
                placeholder="Message"
                required
                name="description"
                value={blogpost.description}
              ></textarea>
            </div>
          </form>
        </div>

        <div className="blogform">
          <form>
            <div className="input-group">
              <span>Heading 1</span>
              <input
                type="text"
                required
                onChange={handleInput}
                placeholder="Enter title1"
                name="headingone"
                value={blogpost.headingone}
              />
            </div>

            <div className="input-group">
              <span>Image Url1</span>
              <input
                type="text"
                onChange={handleInput}
                required
                placeholder="Enter Image Url"
                name="imageone"
                value={blogpost.imageone}
              />
            </div>

            <div className="input-group">
              <span>Description</span>
              <textarea
                rows="8"
                onChange={handleInput}
                placeholder="Message"
                required
                name="descriptionone"
                value={blogpost.descriptionone}
              ></textarea>
            </div>
          </form>
        </div>

        <div className="blogform">
          <form>
            <div className="input-group">
              <span>Heading 2</span>
              <input
                type="text"
                required
                onChange={handleInput}
                placeholder="Enter title1"
                name="headingtwo"
                value={blogpost.headingtwo}
              />
            </div>

            <div className="input-group">
              <span>Image Url2</span>
              <input
                type="text"
                onChange={handleInput}
                required
                placeholder="Enter Image Url"
                name="imagetwo"
                value={blogpost.imagetwo}
              />
            </div>

            <div className="input-group">
              <span>Description 2</span>
              <textarea
                rows="8"
                onChange={handleInput}
                placeholder="Message"
                required
                name="descriptiontwo"
                value={blogpost.descriptiontwo}
              ></textarea>
            </div>
          </form>
        </div>

        <div className="blogform">
          <form>
            <div className="input-group">
              <span>Heading 3</span>
              <input
                type="text"
                required
                onChange={handleInput}
                placeholder="Enter title1"
                name="headingthree"
                value={blogpost.headingthree}
              />
            </div>

            <div className="input-group">
              <span>Image Url3</span>
              <input
                type="text"
                onChange={handleInput}
                required
                placeholder="Enter Image Url"
                name="imagethree"
                value={blogpost.imagethree}
              />
            </div>

            <div className="input-group">
              <span>Description 3</span>
              <textarea
                rows="8"
                onChange={handleInput}
                placeholder="Message"
                required
                name="descriptionthree"
                value={blogpost.descriptionthree}
              ></textarea>
            </div>
          </form>
        </div>

        <div className="blogform">
          <form>
            <div className="input-group">
              <span>Heading 4</span>
              <input
                type="text"
                required
                onChange={handleInput}
                placeholder="Enter title1"
                name="headingfour"
                value={blogpost.headingfour}
              />
            </div>

            <div className="input-group">
              <span>Image Url4</span>
              <input
                type="text"
                onChange={handleInput}
                required
                placeholder="Enter Image Url"
                name="imagefour"
                value={blogpost.imagefour}
              />
            </div>

            <div className="input-group">
              <span>Description 4</span>
              <textarea
                rows="8"
                onChange={handleInput}
                placeholder="Message"
                required
                name="descriptionfour"
                value={blogpost.descriptionfour}
              ></textarea>
            </div>
          </form>
        </div>
        <div className="blogform">
          <form>
            <div className="input-group">
              <span>Heading 5</span>
              <input
                type="text"
                required
                onChange={handleInput}
                placeholder="Enter title1"
                name="headingfive"
                value={blogpost.headingfive}
              />
            </div>

            <div className="input-group">
              <span>Image Url5</span>
              <input
                type="text"
                onChange={handleInput}
                required
                placeholder="Enter Image Url"
                name="imagefive"
                value={blogpost.imagefive}
              />
            </div>

            <div className="input-group">
              <span>Description 5</span>
              <textarea
                rows="8"
                onChange={handleInput}
                placeholder="Message"
                required
                name="descriptionfive"
                value={blogpost.descriptionfive}
              ></textarea>
            </div>
          </form>
        </div>

        <div className="blogform">
          <form>
            <div className="input-group">
              <span>Heading 6</span>
              <input
                type="text"
                required
                onChange={handleInput}
                placeholder="Enter title1"
                name="headingsix"
                value={blogpost.headingsix}
              />
            </div>

            <div className="input-group">
              <span>Image Url6</span>
              <input
                type="text"
                onChange={handleInput}
                required
                placeholder="Enter Image Url"
                name="imagesix"
                value={blogpost.imagesix}
              />
            </div>

            <div className="input-group">
              <span>Description 6</span>
              <textarea
                rows="8"
                onChange={handleInput}
                placeholder="Message"
                required
                name="descriptionsix"
                value={blogpost.descriptionsix}
              ></textarea>
            </div>
          </form>
        </div>

        <div className="blogform">
          <form>
            <div className="input-group">
              <span>Heading 7</span>
              <input
                type="text"
                required
                onChange={handleInput}
                placeholder="Enter title1"
                name="headingseven"
                value={blogpost.headingseven}
              />
            </div>

            <div className="input-group">
              <span>Image Url7</span>
              <input
                type="text"
                onChange={handleInput}
                required
                placeholder="Enter Image Url"
                name="imageseven"
                value={blogpost.imageseven}
              />
            </div>

            <div className="input-group">
              <span>Description 7</span>
              <textarea
                rows="8"
                onChange={handleInput}
                placeholder="Message"
                required
                name="descriptionseven"
                value={blogpost.descriptionseven}
              ></textarea>
            </div>
          </form>
        </div>

        <div className="blogform">
          <form>
            <div className="input-group">
              <span>Heading 8</span>
              <input
                type="text"
                required
                onChange={handleInput}
                placeholder="Enter title1"
                name="headingeight"
                value={blogpost.headingeight}
              />
            </div>

            <div className="input-group">
              <span>Image Url8</span>
              <input
                type="text"
                onChange={handleInput}
                required
                placeholder="Enter Image Url"
                name="imageeight"
                value={blogpost.imageeight}
              />
            </div>

            <div className="input-group">
              <span>Description 8</span>
              <textarea
                rows="8"
                onChange={handleInput}
                placeholder="Message"
                required
                name="descriptioneight"
                value={blogpost.descriptioneight}
              ></textarea>
            </div>
          </form>
        </div>

        <div className="blogform">
          <form>
            <div className="input-group">
              <span>Heading 9</span>
              <input
                type="text"
                required
                onChange={handleInput}
                placeholder="Enter title1"
                name="headingnine"
                value={blogpost.headingnine}
              />
            </div>

            <div className="input-group">
              <span>Image Url9</span>
              <input
                type="text"
                onChange={handleInput}
                required
                placeholder="Enter Image Url"
                name="imagenine"
                value={blogpost.imagenine}
              />
            </div>

            <div className="input-group">
              <span>Description 9</span>
              <textarea
                rows="8"
                onChange={handleInput}
                placeholder="Message"
                required
                name="descriptionnine"
                value={blogpost.descriptionnine}
              ></textarea>
            </div>
          </form>
        </div>

        <div className="blogform">
          <form>
            <div className="input-group">
              <span>Heading 10</span>
              <input
                type="text"
                required
                onChange={handleInput}
                placeholder="Enter title1"
                name="headingten"
                value={blogpost.headingten}
              />
            </div>

            <div className="input-group">
              <span>Image Url10</span>
              <input
                type="text"
                onChange={handleInput}
                required
                placeholder="Enter Image Url"
                name="imageten"
                value={blogpost.imageten}
              />
            </div>

            <div className="input-group">
              <span>Description 10</span>
              <textarea
                rows="8"
                onChange={handleInput}
                placeholder="Message"
                required
                name="descriptionten"
                value={blogpost.descriptionten}
              ></textarea>
            </div>
          </form>
        </div>
        <div>
          <input
            type="submit"
            name="button"
            value="Submit Form"
            onClick={SubmitForm}
          />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default BlogForm;

import React from "react";
import Footer from "./footer";
export default function About() {
  return (
    <div className="container">
      <h1 style={{ color: "black" }}> GET TO KNOW US</h1>

      <p>
        Everything You Need to Know About the Fastest Growing Marketplace App in
        India Ragman is a marketplace app designed to help people buy and sell
        second-hand items in a fast and easy way. Our platform connects buyers
        and sellers from all over India, creating a convenient and sustainable
        way to reuse goods and reduce waste. At Ragman, we believe in the power
        of community and the positive impact that buying and selling second-hand
        items can have on the environment and society. Our app is designed to
        promote a circular economy, where items that are no longer needed can
        find a new home, and people can save money while reducing their
        environmental footprint.
      </p>

      <Footer />
    </div>
  );
}

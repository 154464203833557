import React, { useContext, useEffect, useState } from "react";
import "../styles/UserDashboard.css";
import { useNavigate, useParams } from "react-router-dom";
import NavSideBar from "../components/NavSideBar";
import AdminSearch from "../components/AdminSearch";
import StoreDetails from "../components/StoreDetails";
import CardDetails from "../components/CardDetails";
import UserPost from "../components/UserPost";
import { UserContext } from "../../context/userContext";
import axios from "axios";
const AdminUserIdDashboard = () => {
  const { usrId } = useParams();
  const { setSearchUserData, userInfo } = useContext(UserContext);

  const apiUrl = "https://api.ragman.shop/api/profile/admin/secondUserFetch";
  useEffect(() => {
    const authToken = userInfo?.token;
    const requestBody = {
      userId: usrId,
    };

    axios
      .post(apiUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSearchUserData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [userInfo, usrId]);

  const navigate = useNavigate();
  useEffect(() => {
    getToken();
  }, []);
  const getToken = async () => {
    let token = await localStorage.getItem("userToken");
    if (!token) {
      navigate("/admin/login");
    }
  };

  const [spinner, setSpinner] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setSpinner(false);
    }, 4000);
  });
  return (
    <div className="admin-section">
      <NavSideBar />

      <div className="search-datail-content">
        {/* SEARCH BAR SECTION  */}
        <AdminSearch />

        {/* DOWN CONTENT SECTION */}

        <div className="admin-content">
          {/* DASHBOARD  */}
          <div className="admin-content-dashboardd">
            <div className="dashboard-details">
              <CardDetails />
            </div>

            {/* <div className="dashboard-details">
              <StoreDetails />
            </div> */}
            {/* <div className="dashboard-details">
              <ActiveBtns />
            </div> */}
          </div>
          {/* POSTS  */}

          <UserPost />
        </div>
      </div>
    </div>
  );
};

export default AdminUserIdDashboard;

import React, { useEffect, useState } from "react";
import "../style/blog.css";
import Category from "../components/Category";
import ArticleSection from "../components/ArticleSection";
// import SearchFilter from "../components/SearchFilter";
import Footer from "./footer";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import axios from "axios";
import LatestBlog from "../components/LatestBlog";
import { Helmet } from "react-helmet";

function Blog() {
  const [poster, setPoster] = useState(null);
  useEffect(() => {
    const postUrl = "https://api.ragman.shop/api/category/getbanner";
    axios
      .get(postUrl)
      .then((response) => {
        setPoster(response.data);
      })
      .catch((error) => {
        console.error("Error Blog Poster:", error);
      });
  });

  return (
    <div className="container">
        <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs</title>
        <meta
          name="description"
          content="Discover Ragman, your go-to local marketplace for buy sell items second-hand treasures in your neighborhood. With Ragman, you can effortlessly list and browse a wide range of items, from old cars and bikes to smartphones and laptops, all right in your community.
🏡 Buy Local, Sell Local:
Ragman connects you with your neighbors, making it easy to find hidden gems and sell items you no longer need. Say goodbye to the hassle of shipping and dealing with strangers from afar.
💼 Sell Your Stuff Effortlessly:"
        />
        <meta name="keywords" content="Buy and Sell Used Items ,Local Classifieds ,Secondhand Marketplace ,Buy Used Cars Online,Sell Used Electronics,Secondhand Bikes for Sale,Local Jobs Listings,House Rentals Near Me,Clothes Resale Shop,Secondhand Phones Marketplace"/>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      
      <Navbar />
      <HeroSection />
      <Category />

      <div className="mainDiv">
        <div className="art">
          <div className="innerDiv">
            <ArticleSection />
          </div>
        </div>

        <div className="blog-poster">
          {/* <SearchFilter data={data} />  */}
          {poster?.slice(0, poster.length / 2).map((item) => (
            <div key={item?.image}>
              <img src={item?.image} alt="Loading..." />
            </div>
          ))}
        </div>
      </div>
      <div>
        <LatestBlog />
      </div>

      <Footer />
    </div>
  );
}

export default Blog;

import React, { useState, useEffect } from "react";
import "../style/article.css";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Spinner from "./Spinner";
const ArticleSection = () => {
  //  USE PARAMS
  const { category } = useParams();
  const { pageNumber } = useParams();
  const pageNumberN = Number(pageNumber);

  // STATE OF THE COMPONENT
  const [load, setLoad] = useState(true); //for loader
  const [articlesCar, setArticlesCar] = useState([]);
  const [data, setData] = useState(false); //for check data
  const [nullData, setNullData] = useState(false); //for data not available
  const [expanded, setExpanded] = useState(false);
  const [likes, setLikes] = useState(0);
  const [comments, setComments] = useState(0);

  // FUNCTIONS
  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleLike = () => {
    setLikes(likes + 1);
  };

  const handleComment = () => {
    setComments(comments + 1);
  };

  const handleShare = () => {
    // Implement the share functionality here, e.g., open a share dialog
  };

  const apiUrl = 'https://api.ragman.shop/api/blogs/getcategoryblog';
  //  FETCH BLOG DATA FROM API
  useEffect(() => {
    // BODY FOR POST REQUEST
    const requestBody = {
      category: category,
      page: pageNumber,
    };

    axios
      .post(apiUrl, requestBody)
      .then((response) => {
        setArticlesCar(response.data);

        setData(true);
        setLoad(false);
        if (response.data == "") {
          setNullData(true);
          setData(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <>
      {load && <Spinner />}

      {nullData && (
        <div>
          <h3>!Oops Data is not available...</h3>
        </div>
      )}
      {data && (
        <div>

          
          {articlesCar.map((item) => (
            <div className="article-section" key={item._id}>
              <div style={{ marginBottom: "50px" }}>
                <div className="article-image-container">
                  <img
                    src={item.image}
                    alt="Article"
                    className="article-image"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
                <h2>{item.title}</h2>
                <p>{item.description}</p>
              </div>

              {/* <Link to={`/blog/${item.title}`}> */}
              <Link to={`/blog/${item.title.replace(/\s+/g, "-")}`}>
                <span className="article-span">Read More...</span>
              </Link>
            </div>
          ))}


          <div>
            <div className="more-article-number">
              <div className={pageNumber == 1 ? "articlenumber" : ""}>
                <a href={`/blog/${category}/page/1`}>1</a>
              </div>
              <div className={pageNumber == 2 ? "articlenumber" : ""}>
                <a href={`/blog/${category}/page/2`}>2</a>
              </div>
              <div className={pageNumber == 3 ? "articlenumber" : ""}>
                <a href={`/blog/${category}/page/3`}>3</a>
              </div>
              <div>
                {/* Next */}
                <a href={`/blog/${category}/page/${pageNumberN + 1}`}>Next</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ArticleSection;

import Mobile1 from "../mobile/Rectangle 15 (1).png";
import Mobile2 from "../mobile/Rectangle 16 (1).png";
import Mobile3 from "../mobile/Rectangle 18 (1).png";
import Mobile4 from "../mobile/Rectangle 19 (1).png";
import Mobile5 from "../mobile/Rectangle 17 (1).png";

const Mobile = [
  {
    name1: "Iphone 12 pro max",
    title: "phones",
    location: "Navi mumbai",
    img: Mobile5,
    price: "52,500.00",
  },
  {
    name1: "Samsung galaxy M31",
    title: "phones",
    location: "Faridabad",
    img: Mobile3,
    price: "4,900.00",
  },
  {
    name1: "Realme 8(Cyber black)",
    title: "phones",
    location: "Gurgaon",
    img: Mobile1,
    price: "12,000.00",
  },
  {
    name1: "SI phone 11",
    title: "phones",
    location: "Mumbai",
    img: Mobile2,
    price: "18,000.00",
  },
  {
    name1: "Iphone 11 pro max 256GB",
    title: "phones",
    location: "Punjab",
    img: Mobile4,
    price: "35,000.00",
  },
];
export default Mobile;

import React, { useContext } from "react";
import "../styles/ProductSection.css";
import { UserContext } from "../../context/userContext";
import { SlLocationPin, SlCalender } from "react-icons/sl";
import { PiShare } from "react-icons/pi";
import { MdOutlineReportGmailerrorred } from "react-icons/md";

const ProductSection = () => {
  const { postDetail } = useContext(UserContext);

  return (
    <div className="product-section">
      <h4>Product Details</h4>
      <div className="product-section-img">
        <div>
          <img src={postDetail?.image[1]} alt="" />
        </div>
        <div>
          <img src={postDetail?.image[2]} alt="" />
        </div>
      </div>
      <div className="product-section-some-details">
        <div>
          {" "}
          <i>
            <SlCalender />
          </i>{" "}
          1 month ago
        </div>
        <div>
          {" "}
          <i>
            <MdOutlineReportGmailerrorred />
          </i>{" "}
          Report
        </div>
        <div>
          {" "}
          <i>
            <PiShare />
          </i>{" "}
          Share
        </div>
      </div>
      <div className="product-section-name-price">
        <div className="section-price-name">
          <h6>{postDetail?.productName}</h6>
          <h6>Rs. {postDetail?.price}</h6>
        </div>
        <span style={{ color: "grey" }}>
          <i>
            <SlLocationPin />
          </i>
          {postDetail?.city}
        </span>
        <div className="section-more-detail">
          <div>
            <p>Quantity</p>
            <p>1</p>
          </div>
          <div>
            <p>category</p>
            <p>{postDetail?.category}</p>
          </div>
          <div>
            <p>condition</p>
            <p>{postDetail?.productCondition}</p>
          </div>
          <div>
            <p>brand</p>
            <p>{postDetail?.brand}</p>
          </div>
          <div>
            <p> Year old</p>
            <p>{postDetail?.yearOld}</p>
          </div>
          <div>
            <p>model</p>
            <p>14pro</p>
          </div>

        </div>
        <div className="section-dec-detail">
          <span style={{ color: "grey" }}>Description</span>
          <span>{postDetail?.description}</span>
        </div>
      </div>
    </div>
  );
};

export default ProductSection;

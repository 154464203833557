import cars1 from "../cars/Rectangle 15.png";
import cars2 from "../cars/Rectangle 16.png";
import cars3 from "../cars/Rectangle 18.png";
import cars4 from "../cars/Rectangle 19.png";
import cars5 from "../images/Rectangle 17.png";

const Cars = [
  {
    name1: "Scorpio",
    title: "Cars",
    location: "Navi mumbai",
    img: cars5,
    price: "6,85,000.00",
  },
  {
    name1: "Swift",
    title: "Cars",
    location: "Faridabad",
    img: cars3,
    price: "2,15,000.00",
  },
  {
    name1: "Maruti alto",
    title: "Cars",
    location: "Gurgaon",
    img: cars1,
    price: "85,000.00",
  },
  {
    name1: "Nexon EV",
    title: "Cars",
    location: "Mumbai",
    img: cars2,
    price: "9,76,000.00",
  },
  {
    name1: "WegnoR music system",
    title: "Cars",
    location: "punjab",
    img: cars4,
    price: "3,500.000.00",
  },
];
export default Cars;

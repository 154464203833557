import React, { useContext } from "react";
import "../styles/AdminSearchResults.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import Spinners from "./Spinners";

const AdminSearchResults = () => {
  const navigate = useNavigate();
  const { AdminSearchData, setUserId } = useContext(UserContext);
  const handleClickUserProfile = (usrId) => {
    setUserId(usrId);
    navigate(`/admin/dashboard/${usrId}`);
  };

  return (
    <>
      {AdminSearchData?.length > 0 ? (
        <div className="admin-search-results">
          {AdminSearchData?.map((item) => (
            <div key={item._id} className="admin-search-result">
              <div className="admin-search-result-img">
                <img src={item.avatar} alt="Loading..." />
              </div>
              <div className="admin-search-result-details">
                <h6> {item.name}</h6>
                <button onClick={() => handleClickUserProfile(item.user)}>
                  View Profile
                </button>
                {/* <Link>View Profile</Link> */}
              </div>
            </div>
          ))}
        </div>
      ) : AdminSearchData?.length === 0 ? (
        <div className="search-user-not-found">User Not Found!</div>
      ) : (
        <Spinners />
      )}
    </>
  );
};

export default AdminSearchResults;

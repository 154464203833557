import React, { useEffect, useState } from 'react'
import logo from '../images/Ragman-removebg-preview 2.png';
import logo2 from "../images/app screens.png"
import logo1 from "../images/feature strap.png"
import leftimg from "../images/app feature screen.png";
import grup from "../images/users images.png"
import right from "../images/app screen.png"
import start from "../images/Icon.png"
import star05 from "../images/star-05.png"
import bannerimg from "../images/Frame 1523.png"
import "./Home.css"
import Footer from './footer';
import Card from './card';
import Slider from 'react-slick';
import { MDBContainer } from "mdb-react-ui-kit";
import Cars from "./Cars"
import Bikes from "./Bicke"
import Mobile from "./Mobile"
import ReactGA from 'react-ga';
import Alert from "react-bootstrap/Alert";
import { Helmet } from 'react-helmet';


const Home = () => {

  useEffect(()=>{
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  },)



const event = ()=>{
  // alert('domne')
  ReactGA.event({
    category:"Download click",
    action:'Download'
  })
}
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    responsive: [

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.67,
          slidesToScroll: 1
        }
      }
    ]
  };

  const data = [{
    id: 1,
    name: "Sumeet singh",
    title: "Easy and convenient way to sell ur goods without much hassle.. loved the service."
  }, {
    id: 2,
    name: "KarmesH VashistH",
    title: "Best App for Buy And Sell products And Also a unique concept , great app designs and features"
  },
  {
    id: 2,
    name: "Vishal Jadaun",
    title: "Best app for buying and selling the product Reliable app for purchasing old items"
  },
  {
    id: 1,
    name: "Rishabh Gautam",
    title: "This is good for all person who wants something sell or purchase ...."
  },
  {
    id: 1,
    name: "manisha jaiswal",
    title: "Overall good experience for exploring whole sale market and instant support. A unique idea of service"
  },
  {
    id: 2,
    name: "Gujjar Mavi",
    title: "I really enjoy services from Ragman technology.... they have super expertise in service delivery."
  }
  ]


  const AlertBox = () => {
    return (
      <div>
        <Alert variant="success" style={{ width: "42rem" }}>
          <Alert.Heading>
            This is a success alert which has green background
          </Alert.Heading>
        </Alert>
    
        <Alert variant="secondary" style={{ width: "42rem" }}>
          <Alert.Heading>
            This is a secondary alert which has grey background
          </Alert.Heading>
        </Alert>
    
        <Alert variant="primary" style={{ width: "42rem" }}>
          <Alert.Heading>
            This is a primary alert which has blue background
          </Alert.Heading>
        </Alert>
    
        <Alert variant="danger" style={{ width: "42rem" }}>
          <Alert.Heading>
            This is a danger alert which has red background
          </Alert.Heading>
        </Alert>
    
        <Alert variant="warning" style={{ width: "42rem" }}>
          <Alert.Heading>
            This is a warning alert which has yellow background
          </Alert.Heading>
        </Alert>
    
        <Alert variant="light" style={{ width: "42rem" }}>
          <Alert.Heading>
            This is a light alert which has white background
          </Alert.Heading>
        </Alert>
    
        <Alert variant="dark" style={{ width: "42rem" }}>
          <Alert.Heading>
            This is a dark alert which has dark grey background
          </Alert.Heading>
        </Alert>
    
        <Alert variant="info" style={{ width: "42rem" }}>
          <Alert.Heading>
            This is a info alert which has light blue background
          </Alert.Heading>
        </Alert>
      </div>
    );
  };
    

  const abhi = [1, 2, 3, 4, 5, 6, 7]
  return (
    <div className="container">
          <Helmet>
        <meta charSet="utf-8" />
        <title>Ragman - Buy & Sell Old , Cars , Bikes , Phones</title>
        <meta
          name="description"
          content="Discover Ragman, your go-to local marketplace for buy sell items second-hand treasures in your neighborhood. With Ragman, you can effortlessly list and browse a wide range of items, from old cars and bikes to smartphones and laptops, all right in your community.
🏡 Buy Local, Sell Local:
Ragman connects you with your neighbors, making it easy to find hidden gems and sell items you no longer need. Say goodbye to the hassle of shipping and dealing with strangers from afar.
💼 Sell Your Stuff Effortlessly:"
        />
        <meta name="keywords" content="Buy and Sell Used Items ,Local Classifieds ,Secondhand Marketplace ,Buy Used Cars Online,Sell Used Electronics,Secondhand Bikes for Sale,Local Jobs Listings,House Rentals Near Me,Clothes Resale Shop,Secondhand Phones Marketplace"/>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <div className='row'>
        <div className="header">
          <img src={logo} />

    <a href='https://play.google.com/store/search?q=ragman&c=apps'>
          <button type="button" onClick={()=>event()} className="btn btn-dark">Download</button>
          </a>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6 col-12 logo-section'>
          <span className='gradient1'></span>
          <span className='gradient2'></span>
          <h2 className='main-heading'>Perfect Online marketplace to buy and sell locally!</h2>
          <p>No need to visit the flea market to find the best deals on pre-owned <br></br> items!</p>
          <button type="button" className="btn btn-dark w-10  d-md-block d-none">Get started</button>
          <img className='logo1  d-md-block d-none' src={logo1} />
        </div>
        <div className='col-lg-6 col-12 logo-section-right'>
          <img className="logo2" src={logo2} />
          <span className='gradient3'></span>
        </div>
      </div>
      <div className="container">
        <span className='gradient4'></span>
        <span className='gradient5'></span>
        <p className='feature mb-0 text-center'>Categories</p>
        <h2 className='text-center mb-4 cat-head'>what More We <br></br> Provide?</h2>
        <h6 className='text-center mb-4 mt-4 cat-slide-head'>CARS</h6>
        <div className="row">
          <Slider {...settings}>
            {Cars.map((data, ind) => {
              return <div className='col-4' key={ind}> <Card data={data} /> </div>
            })}
          </Slider>
        </div>
        <h6 className='text-center mb-4 mt-4 cat-slide-head'>Bikes</h6>
        <div className="row">
          <Slider {...settings}>
            {Bikes.map((data) => {
              return <div className='col-4'> <Card data={data} /> </div>
            })}
          </Slider>
        </div>
        <h6 className='text-center mb-4 mt-4 cat-slide-head'>Mobile phones</h6>
        <div className="row">
          <Slider {...settings}>
            {Mobile.map((data) => {
              return <div className='col-4'> <Card data={data} /> </div>
            })}
          </Slider>
        </div>
        <button className='many-more-btn'>Many More</button>
      </div>
      <div className="container">
        <div className='row'>
          <div className='col-lg-6 col-12 order-sm-1 order-1 d-md-block d-none'>
            <div className='p-relate'>
              <img src={leftimg} className='leftimg' />
              <span className='gradient5'></span>
              <span className='gradient6'></span>
            </div>
          </div>
          <div className='col-lg-6 col-12 order-2'>
            <p className='feature mb-0 mt-5 pt-5' >features</p>
            <h2 className='feature-head'>Ragman</h2>
            <spna className="experience">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 2L10.6985 7.20599C10.4445 8.22185 10.3176 8.72978 10.0531 9.14309C9.81915 9.50868 9.50868 9.81915 9.14309 10.0531C8.72978 10.3176 8.22185 10.4445 7.20599 10.6985L2 12L7.20599 13.3015C8.22185 13.5555 8.72978 13.6824 9.14309 13.9469C9.50868 14.1808 9.81915 14.4913 10.0531 14.8569C10.3176 15.2702 10.4445 15.7782 10.6985 16.794L12 22L13.3015 16.794C13.5555 15.7782 13.6824 15.2702 13.9469 14.8569C14.1808 14.4913 14.4913 14.1808 14.8569 13.9469C15.2702 13.6824 15.7782 13.5555 16.794 13.3015L22 12L16.794 10.6985C15.7782 10.4445 15.2702 10.3176 14.8569 10.0531C14.4913 9.81915 14.1808 9.50868 13.9469 9.14309C13.6824 8.72978 13.5555 8.22185 13.3015 7.20599L12 2Z" stroke="#FFBE00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='features-sub-head'>Great shopping experience</p>
            </spna>
            <div className='span'>
              <span className='para-common'>You can browse and search for your desired products by product name, category or brands. With RAGMAN India, you are guaranteed 24x7 customer service support!!</span>
            </div>
            <spna className="experience">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 2.50008V12.0001M12 12.0001L20.5 7.27779M12 12.0001L3.5 7.27779M12 12.0001V21.5001M20.5 16.7223L12.777 12.4318C12.4934 12.2742 12.3516 12.1954 12.2015 12.1645C12.0685 12.1372 11.9315 12.1372 11.7986 12.1645C11.6484 12.1954 11.5066 12.2742 11.223 12.4318L3.5 16.7223M21 16.0586V7.94153C21 7.59889 21 7.42757 20.9495 7.27477C20.9049 7.13959 20.8318 7.01551 20.7354 6.91082C20.6263 6.79248 20.4766 6.70928 20.177 6.54288L12.777 2.43177C12.4934 2.27421 12.3516 2.19543 12.2015 2.16454C12.0685 2.13721 11.9315 2.13721 11.7986 2.16454C11.6484 2.19543 11.5066 2.27421 11.223 2.43177L3.82297 6.54288C3.52345 6.70928 3.37369 6.79248 3.26463 6.91082C3.16816 7.01551 3.09515 7.13959 3.05048 7.27477C3 7.42757 3 7.59889 3 7.94153V16.0586C3 16.4013 3 16.5726 3.05048 16.7254C3.09515 16.8606 3.16816 16.9847 3.26463 17.0893C3.37369 17.2077 3.52345 17.2909 3.82297 17.4573L11.223 21.5684C11.5066 21.726 11.6484 21.8047 11.7986 21.8356C11.9315 21.863 12.0685 21.863 12.2015 21.8356C12.3516 21.8047 12.4934 21.726 12.777 21.5684L20.177 17.4573C20.4766 17.2909 20.6263 17.2077 20.7354 17.0893C20.8318 16.9847 20.9049 16.8606 20.9495 16.7254C21 16.5726 21 16.4013 21 16.0586Z" stroke="#FFBE00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='features-sub-head'>Fast And Cutting-Edge</p>
            </spna>
            <ul>
              <li className='para-common'>List something in seconds, as effortlessly as taking a photo. </li>
              <li className='para-common'>Make money selling your like new items. It’s safe and easy.</li>
            </ul>
            <spna className="experience ">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 2L10.6985 7.20599C10.4445 8.22185 10.3176 8.72978 10.0531 9.14309C9.81915 9.50868 9.50868 9.81915 9.14309 10.0531C8.72978 10.3176 8.22185 10.4445 7.20599 10.6985L2 12L7.20599 13.3015C8.22185 13.5555 8.72978 13.6824 9.14309 13.9469C9.50868 14.1808 9.81915 14.4913 10.0531 14.8569C10.3176 15.2702 10.4445 15.7782 10.6985 16.794L12 22L13.3015 16.794C13.5555 15.7782 13.6824 15.2702 13.9469 14.8569C14.1808 14.4913 14.4913 14.1808 14.8569 13.9469C15.2702 13.6824 15.7782 13.5555 16.794 13.3015L22 12L16.794 10.6985C15.7782 10.4445 15.2702 10.3176 14.8569 10.0531C14.4913 9.81915 14.1808 9.50868 13.9469 9.14309C13.6824 8.72978 13.5555 8.22185 13.3015 7.20599L12 2Z" stroke="#FFBE00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className='features-sub-head'>Fun, Simple And Intuitive</p>
            </spna>
            <ul>
              <li className='para-common'>Discover stuff for sale nearby or search for something specific. </li>
            </ul>
          </div>

        </div>
      </div>

  
<ins class="adsbygoogle"
    
     style={{ display: 'block'}}
     data-ad-layout="in-article"
     data-ad-format="fluid"
     data-ad-client="ca-pub-5472444795686875"
     data-ad-slot="3085408899"></ins>


      <div className="container">
        <div className='row'>
          <div className=' col-lg-6 col-12 '>
            <p className='feature mb-0 mt-5 pt-5' >Advantages</p>
            <h2 className='feature-head'>why choose ragman?</h2>
            <spna className="experience">
              <img src={star05} alt="" />
              <p className='features-sub-head advantage-sub-head'>Powerfull Selling, Simplified</p>
            </spna>
            <div className='span'>
              <span className='para-common'>
                Discover the advantage of powerful selling, simplified with ragman. Our user-friendly platform empowers sellers to effortlessly list their products, connecting them with a vast network of local buyers. Showcase your offerings with eye-catching galleries and detailed descriptions, while our streamlined interface ensures seamless management of your listings.
              </span>
            </div>
          </div>
          <div className='col-lg-6 col-12 order-sm-2 d-md-block d-none p-relate'>
            <img src={right} className='leftimg' />
            <span className='gradient7'></span>
            <span className='gradient8'></span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className='row'>
          <div className='col-lg-6 col-12 order-sm-2 order-1 d-md-block d-none p-relate'>
            <img src={right} className='leftimg' />
            <span className='gradient9'></span>
            <span className='gradient10'></span>
          </div>
          <div className='col-lg-6 col-12 order-2 '>
          <spna className="experience">
              <img src={star05} alt="" />
              <p className='features-sub-head advantage-sub-head'>Diverse Categories, Countless Finds</p>
            </spna>
            <div className='span'>
              <span className='para-common'>
                Explore a world of possibilities with our app's diverse categories and countless finds. Whether you're searching for unique treasures or looking to declutter and sell, our platform offers a rich array of options to cater to your every need. From fashion to electronics, home decor to hobbies, our extensive range ensures you'll discover hidden gems and connect with sellers who share your passions. the joy of exploring diverse categories and finding endless opportunities with Ragman.        </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <p className='feature mb-0 text-center '>testimonial</p>
        <h2 className='text-center mb-2 testi-mono'>what our users <br></br> say about us?</h2>
        <div className='row'>
          <div className=' col-lg-6 col-12 order-sm-2 order-1 p-relate'>
            <img src={grup} className='leftimg' />
            <span className='gradient11'></span>
            <span className='gradient12'></span>
          </div>
          <div className=' col-lg-6 col-12 order-2'>
            <h4 className='mt-5 pt-5 buy-sell'>ragman - buy & sell nearby</h4>
            <div className='span'>
              <span className='para-common'>
                The app is designed with simplicity and ease of use in mind, making it a breeze to navigate through different categories and discover hidden gems. The intuitive layout and thoughtful organization of items make it effortless to find exactly what you're looking for, whether it's vintage clothing, retro electronics, antique furniture, or rare collectibles.
              </span>
            </div>
          </div>
        </div>
      </div>

      <MDBContainer>
      <div className="ratio ratio-16x9">
        <iframe
          src="https://www.youtube.com/embed/o1PirUWHgdU?si=8Fonw8uY_SpgMz6D"
          title="YouTube video"
          allowfullscreen
        ></iframe>
      </div>
    </MDBContainer>

   

<ins class="adsbygoogle"
      style={{ display: 'block'}}
     data-ad-client="ca-pub-5472444795686875"
     data-ad-slot="9558499668"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>


      <h3 className='faq-head'>Frequently Users reviews</h3>
      <div className='container'>
        <div className="row">
          {
            data.map((data, index) => {
              return <div className='col-lg-6 col-12 '>
                <div className={`${data.id === 2 ? "cards1" : "cards2"} `}>
                  <h5 className='faq-name'>{data.name}</h5>
                  <p>{data.title}</p>
                </div>
              </div>
            })
          }
        </div>
      </div>
      <div className="container">
        <a href='https://play.google.com/store/search?q=ragman&c=apps'>
          <img src={bannerimg} className='bannerimg mt-5 d-md-block d-none' />
        </a>
      </div>

     
<ins class="adsbygoogle"
        style={{ display: 'block'}}
     data-ad-format="fluid"
     data-ad-layout-key="+3+q8-s-6+eu"
     data-ad-client="ca-pub-5472444795686875"
     data-ad-slot="5687521500"></ins>


      <Footer />
    </div>
  )
}

export default Home

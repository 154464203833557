import React from "react";
import Footer from "./footer";
import logo from "../images/Ragman-removebg-preview 2.png";

export default function Delete() {
  return (
    <div className="container">
      <div className="row">
        <div className="header">
          <img src={logo} />
          <a href="https://play.google.com/store/search?q=ragman&c=apps">
            <button type="button" onchan className="btn btn-dark">
              Download
            </button>
          </a>
        </div>
      </div>
      <h5 style={{ color: "black", marginLeft: "5%", marginTop: "3%" }}>
        Click the button below to delete your data. The following data will be
        deleted::
      </h5>

      <h6 style={{ color: "#00000080", marginLeft: "5%" }}>Account details</h6>

      <h6 style={{ color: "#00000080", marginLeft: "5%" }}>
        User-generated content
      </h6>

      <h6 style={{ color: "#00000080", marginLeft: "5%" }}>Chat messages</h6>

      <h6 style={{ color: "#00000080", marginLeft: "5%" }}>All your data</h6>

      <a style={{ marginLeft: "5%" }} href="mailto:ragmansupport@ragman.shop">
        <button
          style={{ marginTop: "3%" }}
          type="button"
          onchan
          className="btn btn-dark"
        >
          Delete Data
        </button>
      </a>

      <Footer />
    </div>
  );
}

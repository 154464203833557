import React, { useEffect } from "react";
import AdminSearch from "../components/AdminSearch";
import NavSideBar from "../components/NavSideBar";
import UserDocument from "../components/UserDocument";
import { useNavigate } from "react-router-dom";

const UserDocPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getToken();
  }, []);
  const getToken = async () => {
    let token = await localStorage.getItem("userToken");
    if (!token) {
      navigate("/admin/login");
    }
  };

  return (
    <div className="admin-section">
      {/* NAVSIDE BAR  */}
      <NavSideBar />

      <div className="search-datail-content">
        {/* SEARCH BAR SECTION  */}
        <AdminSearch />

        {/* DOWN CONTENT SECTION */}

        <div className="admin-content">
          {/* DASHBOARD  */}
          <div className="admin-content-dashboard">
            <div className="dashboard-details">
              <UserDocument />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDocPage;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../style/latestBlog.css";

const LatestBlog = () => {
  const [latestBlog, setLatestBlog] = useState(null);

  useEffect(() => {
    const latestBlogUrl = "https://api.ragman.shop/api/blogs/getblog";

    axios
      .post(latestBlogUrl)
      .then((response) => {
        setLatestBlog(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  // Function to truncate text to a specified number of words
  const truncateText = (text, maxWords) => {
    const words = text.split(" ");
    if (words.length <= maxWords) {
      return text;
    }
    return words.slice(0, maxWords).join(" ") + "...";
  };

  return (
    <div className="latest-blog">
      <h2 className="latest-blog-h2">Latest Blogs</h2>
      <div className="latest-blogs">
        <div className="latest-blog1">
          {latestBlog?.map((item) => (
            <div className="latest-blog-section" key={item?._id}>
              <div style={{ marginBottom: "50px" }}>
                <div className="article-image-container">
                  <img
                    src={item?.image}
                    alt="Article"
                    className="latest-blog-image"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
                <h2>{truncateText(item?.title, 10)}</h2>
                <p>{truncateText(item?.description, 20)}</p>
              </div>
              <Link to={`/blog/${item.title.replace(/\s+/g, "-")}`}>
                <span className="article-span">Read More...</span>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestBlog;

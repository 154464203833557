import React, { Component } from "react";
import "../style/footer.css"
import { Switch, Route, Link, Redirect } from "react-router-dom";
import Refund from "./Refund";
import Privacy from "./Privacy";

const Footer = () => {
    return (
        <div className='container'>
            <div className="row footer">
                <div className="col-sm-4 col-12 link">
                    <img src={require("../images/Ragman-removebg-preview 2.png")} alt="" />
                    <ul>
                        <li> <span><img src={require("../images/Frame (1).png")} /></span>ragmansupport@ragman.shop</li>
                        <li><span><img src={require("../images/Vector (1).png")} /></span>+91 8800972717</li>
                    </ul>
                </div>
                <div className="col-sm-4 col-12 link">
                    <h6 className='footer-head-font foot-link'>Social Links</h6>
                    <ul>
                        <li> <span><img src={require("../images/instagram.png")} /></span>
                            <Link to='https://www.linkedin.com/company/ragmanindia/'>  LinkedIn</Link>
                        </li>
                        <li><span><img src={require("../images/google-play.png")} /></span>
                            <Link to='https://play.google.com/store/apps/details?id=com.ragmanindia' >Playstore</Link>
                        </li>
                        <li><span><img src={require("../images/facebook.png")} /></span>
                            <Link to='https://www.facebook.com/Ragmanindia/'>Facebook</Link>
                        </li>
                        <li><span><img src={require("../images/instagram.png")} /></span>
                            <Link to='https://www.instagram.com/ragmanindia/'> Instagram</Link>
                        </li>
                        <li><span><img src={require("../images/product.png")} /></span>
                            <Link to='https://www.producthunt.com/products/ragman-buy-sell-nearby-used-items'> Product hunt</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-sm-4 col-12 link">
                    <h6 className='footer-head-font foot-legal'>legal</h6>
                    <ul>
                        <li>
                            <Link to="/privacypolicy">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to="/about">About</Link>
                        </li>
                        <li>
                            <Link to="/refund"> Refund & Cancellation Policy</Link>
                        </li>
                        <li>
                            <Link to="/delete">Delete My Data</Link>
                        </li>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/blog/Cars/page/1">Blog</Link>
                        </li>
                    </ul>
                </div>
                <div className='footer-copyright'>Copyright 2023 @Ragman | all rights reserved</div>
            </div>

        </div>
    )
}

export default Footer

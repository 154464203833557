import Bikes1 from "../Bick/Rectangle 15 (1).png";
import Bikes2 from "../Bick/Rectangle 16 (1).png";
import Bikes3 from "../Bick/Rectangle 18 (1).png";
import Bikes4 from "../Bick/Rectangle 19 (1).png";
import Bikes5 from "../Bick/Rectangle 17 (1).png";

const Bikes = [
  {
    name1: "Hero splendor",
    title: "Bikes",
    location: "Rajasthan",
    img: Bikes5,
    price: "12,000.00",
  },
  {
    name1: "Bullet",
    title: "Bikes",
    location: "Faridabad",
    img: Bikes3,
    price: "45,000.00",
  },
  {
    name1: "HF deluxe",
    title: "Bikes",
    location: "Gurgaon",
    img: Bikes1,
    price: "18,00.00",
  },
  {
    name1: "Pulsar 150",
    title: "Bikes",
    location: "Mumbai",
    img: Bikes2,
    price: "19,500,000.00",
  },
  {
    name1: "Hero hf deluxe",
    title: "Bikes",
    location: "Punjab",
    img: Bikes4,
    price: "13,500,000.00",
  },
];
export default Bikes;

import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Refund from "./pages/Refund";
import About from "./pages/About";
import Delete from "./pages/Delete";
import ReactGA from "react-ga";
import Blog from "./pages/Blog";
import ArticlePageRoute from "./pages/ArticlePageRoute";
import ProductAll from "./Admin/pages/ProductAll";
import StoreAll from "./Admin/pages/StoreAll";
import UserDocPage from "./Admin/pages/UserDocPage";
import AdminLogin from "./Admin/components/AdminLogin";
import AdminBlogFormPage from "./Admin/pages/AdminBlogFormPage";
import {Helmet} from 'react-helmet';
import SearchResultPage from "./Admin/pages/SearchResultPage";
import { UserProvider } from "./context/userContext";
import AdminUserIdDashboard from "./Admin/pages/AdminUserIdDashboard";

ReactGA.initialize("UA-215603260-2");
ReactGA.pageview(window.location.pathname + window.location.search);


function App() {
  //  STORE USER INFO

  return (
    <UserProvider>
         <Helmet>
        <meta charSet="utf-8" />
        <title>Ragman - Buy & Sell Old , Cars , Bikes , Phones</title>
        <meta
          name="description"
          content="Discover Ragman, your go-to local marketplace for buy sell items second-hand treasures in your neighborhood. With Ragman, you can effortlessly list and browse a wide range of items, from old cars and bikes to smartphones and laptops, all right in your community.
🏡 Buy Local, Sell Local:
Ragman connects you with your neighbors, making it easy to find hidden gems and sell items you no longer need. Say goodbye to the hassle of shipping and dealing with strangers from afar.
💼 Sell Your Stuff Effortlessly:"
        />
        <meta name="keywords" content="Buy and Sell Used Items ,Local Classifieds ,Secondhand Marketplace ,Buy Used Cars Online,Sell Used Electronics,Secondhand Bikes for Sale,Local Jobs Listings,House Rentals Near Me,Clothes Resale Shop,Secondhand Phones Marketplace"/>
      
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacypolicy" element={<Privacy />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/about" element={<About />} />
          <Route path="/delete" element={<Delete />} />
          <Route path="/blog/:category/page/:pageNumber" element={<Blog />} />
          <Route path="/blog/:title" element={<ArticlePageRoute />} />

          {/* ADMIN PANEL ROUTE  */}
          <Route path="/admin/blogform" element={<AdminBlogFormPage />} />
          <Route path="/admin/login" element={<AdminLogin />} />

          <Route
            path="/admin/dashboard/:usrId"
            element={<AdminUserIdDashboard />}
          />
          <Route path="/admin/:usrId/productdetail" element={<ProductAll />} />
          <Route path="/storedetails" element={<StoreAll />} />
          <Route path="/admin/userdocuments" element={<UserDocPage />} />
          <Route path="/admin/search-results" element={<SearchResultPage />} />
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;

import React from "react";
import "../style/spinner.css";
import Loader from "../images/spinner.gif";

const Spinner = () => {
  return (
    <div className="spinners">
      <img src={Loader} alt="error" className="spinner-img" />
      <span>Loading...</span>
    </div>
  );
};

export default Spinner;

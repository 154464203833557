import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // STORE SEARCH USER DATA
  const [searchUserData, setSearchUserData] = useState(null);
  //LOGIN USER DETAILS
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    const storedUserData = localStorage.getItem("userLoginInfo");

    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setUserInfo(parsedUserData);
    }
  }, []);

  // TAKE USER ID OF SEARCH SELECTED
  const [userId, setUserId] = useState("63fb76146a7501d9c0597a5f");
  // SEARCH RESULTS
  const [AdminSearchData, setAdminSearchData] = useState(null);
  // CONTAINS ALL POST OF USER
  const [userPosts, setUserPosts] = useState(null);
  //CONTAIN A POST DETAIL
  const [postDetail, setPostDetail] = useState(null);
  return (
    <UserContext.Provider
      value={{
        AdminSearchData,
        setAdminSearchData,

        userInfo,
        setUserInfo,

        userId,
        setUserId,

        searchUserData,
        setSearchUserData,

        userPosts,
        setUserPosts,

        postDetail,
        setPostDetail,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
